import './style/Label.css';
import React from 'react';
import { } from '../../redux/localSlice';

const Label = ({
    index,
    customRef = null,
    customId = null,
    content = "",
    size = "m",
    line,
    color= "black",
    bold = false,
    mono = false,
    secondary = false,
    onBrand = false,
    maxContent = false,
    uppercase = false,
    invert = false,
    center = false,
    style = {},
    displayFont = false,
    positive = false,
    negative = false,
    right = false,
    hide = false,
    singleLine = false,
    capitalize = false,
    lazy = false,
    pad = null,
    onClick = null,
    flex = null,
    hideMobile = false,
    shadow = false,
    classes = "",
}) => {

    return (
        <p ref={customRef ? customRef : undefined} id={customId ? customId : undefined} index={index ? index : null} onClick={onClick} className={`keystone-label ${!displayFont ? "sf-pro" : "sf-pro-display"} size-${size} ${bold ? "bold" : "medium"} ${line ? "line-" + line : ""} ${mono ? "sf-mono" : ""} ${shadow ? "shadow" : ""}  ${secondary ? "secondary" : ""} ${onBrand ? "on-brand" : ""} ${maxContent ? "max-content" : ""} ${uppercase ? "uppercase" : ""} ${invert ? "invert" : ""} ${center ? "center-text" : ""} ${positive ? "positive" : ""} ${negative ? "negative" : ""} ${right ? "right-align justify-end" : ""} ${color === "brand" ? "brand-color" : color ==="white" ? "white" : color ? color :  "black"}  ${hide ? "hide" : ""} ${singleLine ? "single-line" : ""} ${capitalize ? "capitalize" : ""} ${lazy ? "lazy" : ""} ${onClick ? "label-clickable" : ""} flex grow-0 shrink-1 basis-auto horizontal ${flex ? `flex-${flex}` : ""} ${hideMobile ? "mobile-hide" : ""} ${pad ? `pad-${pad}` : ""} fix-stroke ${classes}`} style={style}>
            {content ? content : ""}
        </p>
    );
};

export default Label;