import './style/ImageTextBlock.css';
import React from 'react';
import TextBlock from './TextBlock';

const ImageTextBlock = ({ image = null, alt = "", title = "", subtitle = "", center = false, vertical = true, classes = ""}) => {
    return (
        <div className={`image-text-block flex v-gap-l h-gap-l flex-1 ${vertical ? "vertical" : "horizontal"} ${center ? "align-center" : ""} ${classes}`}>
            <img src={image} alt={alt} className="value-prop-image" />
            <TextBlock
                title={title}
                subtitle={subtitle}
                center={center} />
        </div>
    );
};

export default ImageTextBlock;