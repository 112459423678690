import './style/Section.css';
import React from 'react';

const Section = ({ bg = "white", classes = "", content, noPadTop = false, noPadBot = false, shiftUp = false, noGrow = false, desktopBasis = "50" }) => {
    return (
        <div className={`flex vertical ${shiftUp ? "shift-section-up" : ""} ${classes}`}>
            <div className={`flex horizontal flex-1 h-gap-xs justify-center ${bg}-bg`}>
                <div className={`flex vertical ${noGrow ? `grow-0 basis-${desktopBasis}-desktop` : ""} flex-1 h-gap-xs v-gap-s v-pad-xl ${noPadTop ? "no-pad-top" : ""} ${noPadBot ? "no-pad-bot" : ""} content-width`}>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default Section;