import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const getApiBaseUrl = () => {
    return window.location.origin.includes('local') ? 'http://localhost:8080' : 'https://shop.trymaterialize.com';
};

export const signupRequest = createAsyncThunk(
    'landing/signupRequest',
    async ({ request }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/landing/signup_request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request)
            });

    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(response.status);
            } else {
                return thunkAPI.fulfillWithValue(response.status);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const temporarySlice = createSlice({
    name: 'temporary',
    initialState: {
        signupProcessing: false,
        signupProcessingError: null,
        signupProcessed: false
    },
    reducers: {
        setSignupProcessingError: (state, action) => {
            state.signupProcessingError = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(signupRequest.pending, (state) => {
            state.signupProcessing = true;
            state.signupProcessingError = null;
        })
        .addCase(signupRequest.fulfilled, (state, action) => {
            state.signupProcessing = false;
            state.signupProcessed = true;
        })
        .addCase(signupRequest.rejected, (state, action) => {
            state.signupProcessing = false;
            state.signupProcessingError = action.payload;
        });
    }
});

export const { setSignupProcessingError } = temporarySlice.actions;

export default temporarySlice.reducer;