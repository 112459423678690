import Label from './Label';
import Stepper from './Stepper';
import './style/StepSection.css';
import React from 'react';

const StepSection = ({ title = "", subtitle = "", step = 1, bg = "white", shiftUp = false, classes = ""}) => {
    return (
        <div className={`step-section flex horizontal mobile-vertical flex-1 h-gap-xs justify-center ${bg}-bg ${shiftUp ? "shift-section-up" : ""} v-pad-xl no-pad-bot ${classes}`}>
            <div className="flex horizontal flex-1 pad-xl space-between align-center">
                <div className="flex flex-1 vertical v-gap-xs">
                    <Label
                        customId={`step-${step}`}
                        content={title}
                        displayFont={true}
                        size="xl"
                        bold={true}
                        color="black"
                    />
                    <Label
                        content={subtitle}
                        mono={true}
                        size="m"
                        line="m"
                    />
                </div>
            </div>
        </div>
    );
};

export default StepSection;