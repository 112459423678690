import Label from './Label';
import './style/TextBlock.css';
import React from 'react';

const TextBlock = ({ title = "", subtitle = "", center = false}) => {
    return (
        <div className="text-block flex vertical v-gap-xs">
            <Label
                content={title}
                color="black"
                displayFont={true}
                size="l"
                bold={true}
                center={center}
            />
            <Label
                content={subtitle}
                secondary={true}
                mono={true}
                size="s"
                line="s"
                center={center}
            />
        </div>
    );
};

export default TextBlock;