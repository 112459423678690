import './style/Input.css';
import React from 'react';

const Input = ({ customId, placeholder, type = "text", width100 = false, value, onKeyDown, onChange, inputRef, flex, large = false, onBrand, onWhite = true, alignRight = false, onFocus = null, onBlur = null, classes = "", autocomplete = null }) => {
    return (
        <input type={type} size={1} autocomplete={autocomplete} id={customId && customId} className={"input sf-mono size-m" + (flex ? " flex-1" : "") + (alignRight ? " align-right" : "") + (large ? " large" : " small") + (onBrand ? " on-brand" : "") + (onWhite ? " on-white" : "") + (width100 ? " width-100" : "") + " " + classes} placeholder={placeholder} onChange={onChange} onFocus={onFocus} onBlur={onBlur} value={value} ref={inputRef} onKeyDown={onKeyDown}>
        </input>
    );
};

export default Input;