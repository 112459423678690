import Label from './Label';
import './style/TestimonialCard.css';
import React from 'react';

const TestimonialCard = ({ image, name, role, company, testimonial = "insert testimonial" }) => {
    return (
        <div className="card flex vertical flex-1 pad-m v-gap-m">
            <div className="flex horizontal flex-1 v-gap-xxs align-center h-gap-l">
                <img src={image} alt="Screen Lock" className="testimonial-image" />
                <div className="flex vertical flex-1 v-gap-xs">
                    <Label
                        content={company}
                        mono={true}
                        bold={true}
                        secondary={true}
                        uppercase={true}
                        size="xs"
                    />
                    <div className="flex vertical flex-1 v-gap-xxs">
                        <Label
                            content={name}
                            displayFont={true}
                            bold={true}
                            size="m"
                        />
                        <Label
                            content={role}
                            displayFont={true}
                            bold={true}
                            secondary={true}
                            size="s"
                        />
                    </div>
                </div>
            </div>
            <div className="grey-bg pad-s round-m">
                <Label
                    content={`“${testimonial}”`}
                    mono={true}
                    size="xs"
                    line="xs"
                    bold={true}
                    classes="blue"
                    center={true}
                />
            </div>
        </div>
    );
};

export default TestimonialCard;