import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './page/Landing';

function App() {
  window.recaptchaOptions = {
    useRecaptchaNet: true,
    enterprise: true,
  };

  return (
    <Router>
      <Routes>
        <Route key={"/"} path={"/"} element={<Landing />} />
        <Route key={"/*"} path={"/*"} element={<Landing />} />
      </Routes>
    </Router>
  );
}

export default App;