import './style/Signup.css';
import React, { useEffect, useState } from 'react';
import Label from './Label';
import LabelInput from './LabelInput';
import LabelSelect from './LabelSelect';
import Button from './Button';
import SimpleLoader from './SimpleLoader';
import ReCAPTCHA from "react-google-recaptcha";
import { signupRequest, setSignupProcessingError } from '../../redux/temporarySlice';
import { useDispatch, useSelector } from 'react-redux';

const Signup = ({ classes, handleHideModal }) => {
    const dispatch = useDispatch();
    const temporary = useSelector((state) => state.temporary);
    const sitekey = window.location?.href?.includes('localhost') ? '6LeRb4AqAAAAAMi0AgNFSMFxyw9bo5q2wePZ9p3u' : '6LeVWn8qAAAAAHHCHAzhrmTQjuadBu9fzePxRzyo'

    const sizeOptions = ["0-50", "51-200", "201-500", "501-1000", "1000+"];
    const [fullName, setFullName] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companySize, setCompanySize] = useState(sizeOptions[0]);
    const [errorsVisible, setErrorsVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [captchaComplete, setCaptchaComplete] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState("");
    const [errorShake, setErrorShake] = useState(false);

    const handleCaptchaChange = (value) => {
        if (value) {
            setCaptchaComplete(true);
            setCaptchaResponse(value);
        }
    };

    useEffect(() => {
        if (temporary.signupProcessed) {
            setLoading(false);
            setTimeout(() => {
                handleHideModal();
            }, 3000);
            console.log('Signup response:', temporary.signupResponse);
        }
    }, [temporary.signupProcessed]);

    useEffect(() => {
        if (temporary.signupProcessingError) {
            setLoading(false);
            setTimeout(() => {
                setSignupProcessingError(null);
            }, 5000);
        }
    }, [temporary.signupProcessingError]);

    useEffect(() => {
        setErrorsVisible(false);
    }, [fullName, companyEmail, companyName, companySize]);

    const handleSignup = () => {
        if (fullName === '' || companyEmail === '' || !validateEmail(companyEmail) || companyName === '' || companySize === '' || !captchaComplete) {
            setErrorsVisible(true);
            setErrorShake(true);
            setTimeout(() => {
                setErrorShake(false);
            }, 1000);
            return;
        } else {
            setErrorsVisible(false);
            setLoading(true);
            // submit request

            let request = {
                recaptcha_response: captchaResponse,
                email: companyEmail,
                name: fullName,
                company_name: companyName,
                metadata: {
                    company_size: companySize
                }
            }
    
            dispatch(signupRequest({ request }));
        }
    };

    const validateEmail = (email) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
    };

    return (
        <div id="signup-modal-wrapper" className={`flex vertical flex-1 align-center justify-center ${classes}`}>
            <div id="signup-close" className="flex horizontal flex-1 justify-end">
                <Button label="Close" className="flex horizontal align-end justify-end" onClick={handleHideModal} onBrand={true} />
            </div>
            {temporary.signupProcessed && <div id="signup-modal" className={`pad-xl flex vertical overflow-y-scroll v-gap-xxl ${temporary.signupProcessed ? "fade-slide-in-d" : "fade-slide-out-d"}`}>
                <div className="flex vertical pad-xl v-gap-s">
                    <Label content="You're signed up!" displayFont={true} size="xl" bold={true} color="white" />
                    <Label content="We're excited to have you on board!" mono={true} size="m" bold={true} color="white" />
                </div>
            </div>}
            {!temporary.signupProcessed && <div id="signup-modal" className={`pad-xl flex vertical overflow-y-scroll ${loading ? "dim" : ""} ${temporary.signupProcessed ? "fade-slide-out-d" : "fade-slide-in-d"}`}>
                <div className={`flex vertical pad-xl v-gap-xl ${errorShake ? "error-shake" : ""}`}>
                    <div className="flex vertical v-gap-s relative">
                        <Label content="Manufacturer Signup" displayFont={true} size="xl" bold={true} color="white" />
                        <Label content="We're excited to have you on board!" mono={true} size="m" bold={true} color="white" />
                        {temporary.signupProcessingError && (<div id="submit-error" className={`flex vertical red-bg pad-m round-s ${temporary.signupProcessingError ? "fade-slide-in-d" : "fade-slide-out-d"}`}>
                            <Label content="Hmm, we had an issue submitting your request." mono={true} size="s" bold={true} color="black" center={true} />
                        </div>)}
                    </div>
                    <div className="flex vertical v-gap-m round-m">
                        <Label
                            content="Enter your details"
                            displayFont={true}
                            size="m"
                            bold={true}
                            color="white" />
                        <div className="flex vertical v-gap-l">
                            <div className="flex flex-1 horizontal mobile-vertical h-gap-m v-gap-m">
                                <LabelInput
                                    label="Full Name"
                                    flex={true}
                                    value={fullName}
                                    type="text"
                                    placeholder="Alice Smith"
                                    onChange={(e) => setFullName(e.target.value)}
                                    classes={(errorsVisible && fullName === '') ? "error" : ""} />
                            </div>
                            <LabelInput
                                label="Email"
                                flex={true}
                                type="email"
                                autocomplete='email'
                                placeholder='alice@company.com'
                                value={companyEmail}
                                onChange={(e) => setCompanyEmail(e.target.value)}
                                classes={(errorsVisible && fullName !== '' && (companyEmail === '' || !validateEmail(companyEmail))) ? "error" : ""} />
                            <LabelInput
                                label="Company Name"
                                flex={true}
                                value={companyName}
                                type="text"
                                autocomplete='organization'
                                placeholder="Company Inc."
                                onChange={(e) => setCompanyName(e.target.value)}
                                classes={(errorsVisible && fullName !== '' && companyEmail !== '' && companyName === '') ? "error" : ""} />
                        </div>
                    </div>
                    <div className="flex vertical v-gap-m">
                        <div className={`flex vertical align-end ${(errorsVisible && fullName !== '' && companyEmail !== '' && companyName !== '' && !captchaComplete) ? "error" : ""}`}>
                            <ReCAPTCHA
                                sitekey={sitekey} 
                                onChange={handleCaptchaChange} />
                        </div>
                        <button className="primary-button bold sf-mono uppercase size-m" onClick={handleSignup}>Signup</button>
                        <Label content="Check your email for your magic access link." mono={true} size="s" line="s" bold={true} color="white" center={true} />
                    </div>
                </div>
            </div>}
            <SimpleLoader loading={loading} />
        </div>
    );
};

export default Signup;