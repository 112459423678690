import { createSlice } from '@reduxjs/toolkit';

export const localSlice = createSlice({
    name: 'local',
    initialState: {
        cookies: "unset",
    },
    reducers: {
        setCookies: (state, action) => {
            state.cookies = action.payload;
        },
    },
});

export const { setCookies } = localSlice.actions;

export default localSlice.reducer;